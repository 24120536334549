<template>
    <div class="zkfx-index">
        <div class="top-nav">
            <div 
                v-for="(item, index) in navList" 
                :key="index"
                :class="{ 'actice-nav': index == navId }"
                @click="onChangeNav(index,item.revisePeriodID)"
            >
                {{ item.revisePeriodName }}
            </div>
        </div>
        <!-- 内容 -->
        <div class="content" v-if="showPage">
            <first-review v-if="navId == 0" :fuxiPeriod="fuxiPeriod"></first-review>
            <second-review v-else :fuxiPeriod="fuxiPeriod"></second-review>
        </div>
    </div>
</template>

<script>
import FirstReview from '../SunSpecialRes/Index.vue';
import SecondReview from '../SunSpecialRes/Index.vue';
export default {
    name: 'ZkfxIndex',
    props: [],
    components: {
        FirstReview,
        SecondReview
    },
    data() {
        return {
            navList: [
                // {name: '一轮复习'},
                // {name: '二轮复习'}
            ],
            navId: 0,
            fuxiPeriod: '',
            showPage: false,
        };
    },
    watch: {

    },
    computed: {

    },
    methods: {
        onChangeNav(idx,id) {
            this.navId = idx;
            this.fuxiPeriod = id;
        }
    },
    created() {
        const period = this.getSessionVal('sc_period');

// 显成说 精准区 120449/外正 121346/外测 -> 23 120276/预发布，其他区是 3
// const zoneId = this.loadAccount().zoneidx;
// 【【罗湖授权】后台创建的教辅   页面没有显示】https://www.tapd.cn/58933855/bugtrace/bugs/view?bug_id=1158933855001028494==修改courseID为菜单id
const course = JSON.parse(sessionStorage.getItem('courseID'));

// let api = `api/CourseRevisePeriod?PeriodID=${this.getSunAccount('PeriodID')}&CourseID=3`
// let api = this.getMasterDataCenterUrl() + `/api/CourseRevisePeriod/GetCourseRevisePeriodList?PeriodID=3&CourseID=3`; // get
let api = this.getMasterDataCenterUrl() + `/api/CourseRevisePeriod/GetCourseRevisePeriodAndSeriesCountList`; // post
let params = {
  "getRevisePeriodQuery": {
    "periodID": period,
    "courseID": course
  },
  "getSeriesListQuery": {
    "periodID": period,
    "subjectID": sessionStorage.getItem('sc_subject'),
    "termID": 0,
    "zoneID": sessionStorage.getItem('sc_zone'),
    "courseID": course,
    "schoolID": 0,
    "revisePeriodID": 0
  }
}

        this.$http.post(api, params).then(res => { 
            if (res.status == 200) {
                res.data.data.forEach(v => {
                    if (v.courseName == '中考复习') {
                        this.navList = v.revisePeriodList;
                        this.fuxiPeriod = v.revisePeriodList[0].revisePeriodID;
                        this.showPage = true
                    }
                });
            }
        })
    },
    mounted() {
    
    }
};
</script>

<style lang="less" scoped>
.zkfx-index {
    padding-left: 80px;
    padding-right: 0;
}
.top-nav {
    height: 60px;
    cursor: pointer;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    font-size: 18px;
    .actice-nav {
        color: #22B5AD;
        text-decoration: underline;
    }
}
.content {
    padding-right: 15px;
    height: calc(100vh - 60px);
    overflow-y: auto;
}
.content::-webkit-scrollbar{
    width: 4px;
}
.content::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #d8d8d8;
}
</style>
