import { render, staticRenderFns } from "./ZkfxIndex.vue?vue&type=template&id=4373c632&scoped=true&"
import script from "./ZkfxIndex.vue?vue&type=script&lang=js&"
export * from "./ZkfxIndex.vue?vue&type=script&lang=js&"
import style0 from "./ZkfxIndex.vue?vue&type=style&index=0&id=4373c632&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4373c632",
  null
  
)

export default component.exports